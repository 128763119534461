.home-container{
	display: grid;
	grid-template-columns: repeat(10, 200px);
	grid-template-rows:repeat(8, 150px);
	grid-gap: 10px;
	position: absolute;
	top: 50px;
	padding-right: 10px;
	padding-bottom: 10px;
}

.garden {
	border: 2px solid white;
	padding: 10px;
}

.river {
	background-color: #5377D4;
}

.rocks {
	background-color: #999966;
}

.terrace {
	background-color: #669900;
}

.flower {
  position: absolute;
}

.garden-container {
  height: 90%;
  position: relative;
}

img {
	width: 400px;
}