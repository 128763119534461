span {
	margin-right: 30px;
}

a {
	text-decoration: none;
	color: inherit;
}

.nav {
	height: 30px;
	position: fixed;
	width: 100%;
	background-color: #009933;
	z-index: 2;
	padding: 10px 0px 0px 10px;
	top: 0px;
	left: 0px;
}

.pageContainer{
	position: absolute;
	top: 50px;
	width: 60%;
	min-width: 450px;
	max-width: 750px;
	margin-left: 20px;
	padding-bottom: 100px;
	line-height: 1.2;
}