.images {
	display: flex;
}

.images > img {
	max-width: 200px;
}

.section {
	margin-bottom: 20px;
}